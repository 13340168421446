import './IndependentUser.scss';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import getQueryVariable from '../utility/getQueryVariable';
import { loadSegment } from '../utility/segment';
import PasswordInput from '../PasswordInput';
import ExternalProviders from '../externalproviders/ExternalProviders';
import Loader from '../utility/Loader';
import Background from '../Background';
import { canIUseAsync } from '../utility/featureflags/featureFlagUtility';
import { Features } from '../utility/featureflags/featureFlags';

export function IndependentUserSetup() {
  const returnUrl = getQueryVariable('returnUrl');
  const email = getQueryVariable('email');
  const clientId = getQueryVariable('clientId');
  const errorFromQuery = getQueryVariable('error');
  const expires = getQueryVariable('expires');
  const token = getQueryVariable('token');

  const [showLoading, setShowLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);
  const [password, setPassword] = useState<string>();
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    validateLink();
    document.title = t('SignupInviteTitle');
    loadSegment();
    window.analytics.page('invite');
  }, []);

  const isFormValid = () => {
    return validPassword;
  };

  const validateLink = async () => {
    try {
      await Axios('/authenticate/validations/invitetoken', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          email: email,
          expires: expires,
          token: token,
          clientId: clientId
        }
      });

      setShowLoading(false);
    } catch (error) {
      const title = t('ContactAdministrator');
      const message = error?.response?.data?.message || t('UnknownError');
      window.location.assign(`/error?title=${title}&message=${message}`);
    }
  };

  const onPasswordChange = (value: string, isValid: boolean) => {
    setPassword(value);
    setValidPassword(isValid);
  };

  const onGoFormzProviderSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowLoading(true);
    setErrorMessage(null);

    if (!isFormValid()) {
      return;
    }

    try {
      await Axios('/authenticate/resetpassword', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          username: email,
          expires,
          token,
          newPassword: password,
          returnUrl,
          invite: true,
          clientId
        }
      });

      const redirectToGenericUserSetupSurvey = await canIUseAsync(clientId, Features.ShowSetupForInvitedNormalUsers, false);

      const redirect = new URL(window.location.href);

      //to-do: remove IndenpendentUserSurvey entirely after UserSetupSurvey is fully validated to consolidate
      redirect.pathname = redirectToGenericUserSetupSurvey ? '/usersetupsurvey' : '/independentusersurvey';
      window.location.href = redirect.href;
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  const onExternalSignup = async (providerId: string) => {
    setShowLoading(true);
    setErrorMessage(null);

    await Axios('/authenticate/independentusersetup?login=false', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        returnUrl: returnUrl,
        requestData: {
          emailAddress: email,
          clientId: clientId
        }
      }
    });

    const generatedForm = document.createElement('form');
    generatedForm.action = `${window.location.origin}/authenticate/oauthlogin`;
    generatedForm.method = 'POST';

    try {
      const data = {
        username: email,
        returnUrl: returnUrl,
        providerId,
        bypassConnectApproval: true,
        authorizationFailedReturnUrl: window.location.href
      };

      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = property;
          input.value = data[property];
          generatedForm.appendChild(input);
        }
      }

      document.body.appendChild(generatedForm);
      generatedForm.submit();
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  if (showLoading) {
    return <Loader isLoading />;
  }

  return (
    <div className="signup-invite">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://www.goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('IndependentUserSetup')}</span>
          </div>

          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}

          <div className="invite-description">
            <span>{t('IndependentUserInviteText')}</span>
          </div>

          <form name="signupinvite" onSubmit={onGoFormzProviderSignup}>
            <div className="goformz-provider">
              <PasswordInput placeholder={t('PasswordFieldPlaceholder')} onChange={onPasswordChange} autofocus={true} useConfirmPassword={true} />
            </div>
            <div className="login">
              <Button disabled={!isFormValid()} type="submit" className="ui primary button">
                {t('NextButton')}
              </Button>
            </div>
          </form>
          <ExternalProviders showOrLine onProviderClick={onExternalSignup}></ExternalProviders>
        </div>
      </div>
    </div>
  );
}
